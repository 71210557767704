import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import AppBar from "@/components/AppBar"
import FootBar from "@/components/FootBar"
import VueCoreVideoPlayer from 'vue-core-video-player'

Vue.config.productionTip = false

//全局注册AppBar
Vue.component("AppBar", AppBar);
//全局注册FootBar
Vue.component("FootBar", FootBar);
//vue-core-video-player视频组件
Vue.use(VueCoreVideoPlayer,{
    lang: 'zh-CN'
})

new Vue({
    router, store, vuetify, render: h => h(App)
}).$mount('#app')
