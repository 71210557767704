<template>
  <v-footer
      padless
  >
    <v-card
        height="50"
        class="grey darken-2 white--text"
        width="100%"
    >
      <v-card-text id="textFont" class="grey darken-3 white--text text-center">
        深圳致开科技有限公司 版权所有 COPYRIGHT &copy; 2015-{{ new Date().getFullYear() }} - <a href="https://beian.miit.gov.cn"
                                                                                  target="_blank"
                                                                                  style="color: white; text-decoration: none"><strong>粤ICP备16004202号</strong></a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FootBar",
}
</script>

<style scoped>
/*解决浏览器不同字体大小显示问题，可以采用Arial字体*/
/*#textFont{*/
/*  font-family: 'Arial'*/
/*}*/
</style>
