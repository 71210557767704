<template>
  <div>
    <!--    应用栏-->
    <v-app-bar
        id="home-app-bar"
        app
        elevation="1"
        elevate-on-scroll
        height="80"
        color="#FFFFFF"
    >
      <!--      占用边距-->
      <v-sheet
          color="white"
          height="80"
          width="80"
          class="hidden-md-and-down mr-16"
      ></v-sheet>
      <!--      logo图1-->
      <v-img
          src="@/assets/logo1.png"
          class="mr-3 hidden-xs-only"
          max-width="46"
      >
      </v-img>
      <!--      logo图2-->
      <v-img
          src="@/assets/logo2.png"
          max-width="96"
      >
      </v-img>
    </v-app-bar>
  </div>
</template>

<script>

export default {
  name: "AppBar"
}
</script>

<style lang="sass">
#home-app-bar
  .v-tab
    &::before
      display: none
</style>
