<template>
  <v-app class="fill-height">
    <!--  应用栏-->
    <App-Bar></App-Bar>
    <v-main>
      <!--  视频-->
      <Video-View></Video-View>
    </v-main>
    <!--  底部-->
    <Foot-Bar></Foot-Bar>
  </v-app>
</template>

<script>
import VideoView from "@/views/home/VideoView";

export default {
  name: 'Home',
  components: {
    VideoView
  },
}
</script>
